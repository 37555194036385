import history from "routing/history";
import { getRoutes } from "routing/routes";
/**
 * Return configured history, routes and store
 * @param  {Object} initialState
 * @return {Object} Object containting configured store, routes, history
 */
export default (): { routes: any, history: any } => {
  const routes = getRoutes();
  // const store = configureStore(initialState);
  return {
    // store,
    routes,
    history,
  };
};
