export const Env = {
  isDebug: true,
  hiddenMode: false,
  showSideMenu: true,
  isBrowser: process.env.REACT_APP_BROWSER,
};

export const KEY = {
  token: "token",
  userId: "userid",
  email: "email",
};

export const LOCALSTORAGE = {
  USER_INFO: 'USER_INFO',
}

export const COOKIES = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
}