import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { authService } from "./auth";

export const client = axios.create({
  baseURL:
    process.env.REACT_APP_BASE_API_URL || "https://api.s-vip.vn/api",
});

const getAuthorization = () => {
  return authService.isLoggedIn()
    ? `Bearer ${authService.getAccessToken()}`
    : "";
};

const requestInterceptor = (request: AxiosRequestConfig) => {
  request.headers["Authorization"] = getAuthorization();
  return request;
};

const responseSuccessInterceptor = (response: AxiosResponse) => {
  return response;
};

const responseErrorInterceptor = (error: AxiosError) => {
  // Do something with response error - logging bla bla
  console.error(error);
  return Promise.reject(error);
};

client.interceptors.request.use(requestInterceptor);
client.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor
);
