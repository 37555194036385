import React from "react";
import PropTypes from "prop-types";
import AppFrame from "../AppFrame/index";
import RoutingWrapper from "../Route/RoutingWrapper/index";
import { getRouterRoutes } from "../../routing/index";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { apoloClient } from "common/apollo";
// import {Env} from 'constants/Config';
// import {IntlProvider, defineMessages, addLocaleData} from 'react-intl'

function Root({ routes }) {
  return (
    <ApolloProvider client={apoloClient}>
      <Router>
        <AppFrame>
          <RoutingWrapper routes={getRouterRoutes(routes)} />
        </AppFrame>
      </Router>
    </ApolloProvider>
  );
}

Root.propTypes = {
  routes: PropTypes.array.isRequired,
  i18n: PropTypes.object,
  SSR: PropTypes.object,
  history: PropTypes.object,
};
export default Root;
