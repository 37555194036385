import "./App.scss";
import { ConfigRoot, ConfigInit } from "./common/config_app/index";

// @ts-ignore
const i18n = window.__I18N__ || {};

const { routes, history } = ConfigInit();
const RootComponent = ConfigRoot({
  routes,
  history,
  i18n,
});

export default RootComponent;
