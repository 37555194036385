// @ts-nocheck
import React, { useState } from "react";
import { UpOutlined, RightOutlined } from "@ant-design/icons";
import Label from "components/general/label";
import { Env } from "config/constants";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Popover } from "antd";
import { getSidebarRoutes } from "routing/routes";

const sidebarRoutes = getSidebarRoutes();

const DivWrap = styled.div(
  ({
    height,
    isSelected,
    collapsed,
  }: {
    height: string;
    isSelected: boolean;
    collapsed: boolean;
  }) => ({
    height,
    backgroundColor: isSelected ? "white" : "transparent",
    color: isSelected ? "black" : "rgb(191, 203, 217)",
    display: "flex",
    alignItems: "center",
    justifyContent: collapsed ? "center" : "space-between",
    "&:hover": {
      backgroundColor: isSelected ? "white" : "#2d325f",
    },
    marginTop: collapsed ? "0px" : "16px",
    borderTopLeftRadius: collapsed ? "0px" : "16px",
    borderBottomLeftRadius: collapsed ? "0px" : "16px",
    paddingLeft: collapsed ? "0px" : "16px",
    paddingRight: collapsed ? "0px" : "16px",
    whiteSpace: "nowrap",
    cursor: "pointer",
  })
);

const WrapSubMenu = styled.div(
  ({ collapsed, isSelected }: { collapsed: boolean; isSelected: boolean }) => {
    let color = isSelected ? "red" : "rgb(191, 203, 217)";
    let bgHover = collapsed ? "#2d325f" : "#0f1028";
    if (collapsed) {
      color = isSelected ? "#253f60" : "white";
    }
    return {
      height: "50px",
      display: "flex",
      alignItems: "center",
      backgroundColor: collapsed ? "#131432" : "transparent",
      color,
      paddingLeft: collapsed === true ? "8px" : "30px",
      paddingRight: "16px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: bgHover,
      },
      whiteSpace: "nowrap",
    };
  }
);

function SubMenuItem({
  title,
  isSelected = false,
  collapsed,
  onClick,
}: {
  title: string;
  isSelected?: boolean;
  collapsed?: boolean;
  onClick?: (e: any) => void;
}) {
  return (
    <WrapSubMenu
      isSelected={isSelected}
      collapsed={collapsed}
      onClick={onClick}
    >
      {!collapsed && <RightOutlined style={{ fontSize: "10px" }} />}
      <Label title={title} padding="0px 16px" color="inherite" />
    </WrapSubMenu>
  );
}

function MenuItem({
  title,
  icon,
  isSelected = false,
  showArrow,
  isExpanded,
  height = "40px",
  onClick,
  collapsed,
  menu,
}: // key,
{
  title: string;
  icon: React.ReactNode;
  isSelected?: boolean;
  showArrow?: boolean;
  isExpanded?: boolean;
  height?: string;
  onClick?: (e: any) => void;
  collapsed?: boolean;
  menu?: JSX.Element;
  // key?: string;
}) {
  const [state, setState] = useState({ visible: false });

  const hide = () => {
    setState({
      visible: false,
    });
  };

  const handleVisibleChange = (visible) => {
    setState({ visible });
  };

  return (
    <Popover
      // key={key}
      content={collapsed ? <div onClick={hide}>{menu}</div> : null}
      placement="rightTop"
      visible={state.visible}
      onVisibleChange={handleVisibleChange}
    >
      <DivWrap
        height={height}
        isSelected={isSelected}
        collapsed={collapsed}
        onClick={onClick}
      >
        <div>
          {icon}
          {!collapsed && (
            <Label
              title={title}
              padding="0px 16px"
              color="inherite"
              style={{
                visibility: collapsed ? "hidden" : "visible",
                // whiteSpace: "nowrap",
              }}
            />
          )}
        </div>
        {showArrow && !collapsed && (
          <UpOutlined
            className={isExpanded ? "rotate_up" : "rotate_down"}
            style={{ fontSize: "12px" }}
          />
        )}
      </DivWrap>
    </Popover>
  );
}
const expanded: { [key: string]: boolean } = {};

function SideMenu({
  collapsed,
  onSelectMenu,
}: {
  collapsed?: boolean;
  onSelectMenu?: (e?: any) => void;
}) {
  const [state, setState] = useState({
    // path: "1",
    isExpanded: expanded,
  });
  const location = useLocation();
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="logo">
        <a className="sidebar-logo-link">
          S-VIP.VN
          {/* {!Env.hiddenMode && (
            <img src="/img/newLogo.edd017f6.png" className="sidebar-logo" />
          )} */}
          {/* {!Env.hiddenMode && (
            <img src="/img/logo_vs.jpg" className="sidebar-logo" />
          )} */}
        </a>
      </div>
      <div
        style={{
          paddingLeft: collapsed ? undefined : "24px",
          paddingTop: collapsed ? undefined : "5px",
          height: "100%",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#131432",
        }}
      >
        {Env.showSideMenu &&
          sidebarRoutes.map((item, index) => {
            if (item.childrens == null) {
              return (
                <MenuItem
                  key={`side_bar_menu_${item.path}`}
                  title={item.name}
                  icon={item.icon}
                  height={index === 0 ? "50px" : undefined}
                  isSelected={location.pathname === item.path}
                  collapsed={collapsed}
                  onClick={() => {
                    setState({ ...state, isExpanded: {}, path: item.path });
                    history.push(item.path);
                    onSelectMenu && onSelectMenu();
                  }}
                />
              );
            }
            const subMenus = item.childrens.map((child) => {
              return (
                <SubMenuItem
                  key={"SubMenuItem_expand_menu" + child.path}
                  title={child.name}
                  isSelected={location.pathname === child.path}
                  collapsed={collapsed}
                  onClick={() => {
                    setState({ ...state, path: child.path });
                    history.push(child.path);
                    onSelectMenu && onSelectMenu();
                  }}
                />
              );
            });

            return (
              <React.Fragment key={"fragment_expand_menu" + index}>
                <MenuItem
                  key={"fragment_expand_menu" + index}
                  title={item.name}
                  icon={item.icon}
                  showArrow
                  isExpanded={state.isExpanded[item.path]}
                  isSelected={
                    location.pathname.substr(
                      0,
                      location.pathname.indexOf("/", 1)
                    ) === item.path
                  }
                  // isSelected={location.pathname.indexOf(item.path) >= 0}
                  collapsed={collapsed}
                  menu={<div>{subMenus}</div>}
                  onClick={() => {
                    if (collapsed) {
                      return;
                    }
                    setState({
                      ...state,
                      isExpanded: { [item.path]: !state.isExpanded[item.path] },
                    });
                  }}
                />
                <AnimatePresence initial={false}>
                  {state.isExpanded[item.path] && !collapsed && (
                    <motion.div
                      key="content"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 },
                      }}
                      transition={{
                        duration: 0.4,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      {subMenus}
                    </motion.div>
                  )}
                </AnimatePresence>
              </React.Fragment>
            );
          })}
      </div>
    </React.Fragment>
  );
}
export default React.memo(SideMenu);
