import React from "react";
import PropTypes from "prop-types";
import Root from "components/Root/index";

const ConfigRootComponent = (props: any) => {
  return <Root {...props} />;
};
ConfigRootComponent.propTypes = {
  // store: PropTypes.object.isRequired,
  // SSR: PropTypes.object,
  routes: PropTypes.array.isRequired,
  i18n: PropTypes.object,
  history: PropTypes.object,
};

export default ConfigRootComponent;
