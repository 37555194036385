import React, {
  Suspense,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { UserAction, UserContext, UIContext } from "context/index";
import NavBar from "./narbar/narbar";
import { getUserInfo, isLoggedIn, resetLocalToken } from "common/localStorage";
import ErrorBoundary from "./ErrorBoundary";
import "./app_frame.css";
import useWindowSize from "hooks/useWindowSize";
import SideBar from "./sidebar";
import "moment/locale/vi";
import locale from "antd/es/locale/vi_VN";
import { Layout, ConfigProvider, Spin, Space } from "antd";
import { authService } from "services/auth";
const { Header, Content } = Layout;

// let appRouting = getRouterRoutes();

const userReducer = (preState: any, action: any) => {
  switch (action.type) {
    case UserAction.setProfile:
      let profile = action.payload;
      return { ...preState, ...profile };
    case UserAction.logout:
      authService.logout();
      return {};
    default:
      throw new Error("Unexpected action");
  }
};

function AppFrame({ children }: { children: any }) {
  const [user, dispatchUser] = useReducer(userReducer, {
    isLoggedIn: authService.isLoggedIn(),
    ...authService.getUserInfoLocal(),
  });
  // state
  const size = useWindowSize();
  const [state, setState] = useState({
    drawerCollapsed: size.width > 1024,
    drawerOpen: size.width > 1024,
    drawerDocked: size.width > 1024,
  });
  // Function for auth context
  const logout = useCallback(function () {
    dispatchUser({ type: UserAction.logout });
  }, []);
  const setProfile = useCallback(function (profile: any) {
    dispatchUser({ type: UserAction.setProfile, payload: profile });
  }, []);
  // callback GraphQLContext

  useEffect(() => {
    setState({
      drawerCollapsed: size.width > 1024 && state.drawerCollapsed,
      drawerOpen: size.width > 1024,
      drawerDocked: size.width > 1024,
    });
  }, [size]);

  const onCloseDrawer = () => {
    if (!state.drawerDocked && state.drawerOpen) {
      setState({
        ...state,
        drawerOpen: false,
      });
    }
  };
  const toggleDrawer = () => {
    setState({
      drawerCollapsed: size.width > 1024 ? !state.drawerCollapsed : false,
      drawerOpen: size.width > 1024 ? true : !state.drawerOpen,
      drawerDocked: size.width > 1024,
    });
  };

  const loggedIn = authService.isLoggedIn();

  return (
    <UIContext.Provider
      value={
        {
          // loading,
          // togleLoading: (value, title) => {
          //   setLoading(value);
          //   setTitleLoading(title);
          // },
        }
      }
    >
      <ConfigProvider locale={locale}>
        <UserContext.Provider value={{ user, setProfile, logout }}>
          <ErrorBoundary>
            {loggedIn && (
              <Layout style={{ height: "100vh", overflow: "hidden" }}>
                <SideBar
                  open={state.drawerOpen}
                  docked={state.drawerDocked}
                  collapsed={state.drawerCollapsed}
                  onClose={onCloseDrawer}
                />
                <Layout className="site-layout">
                  <Header
                    className="site-layout-background"
                    style={{ padding: 0, height: "50px" }}
                  >
                    <NavBar
                      drawerCollapsed={state.drawerCollapsed}
                      toggleDrawer={toggleDrawer}
                    />
                    {/* <div style={{ display: "flex" }}>
            <p>Test</p>
          </div> */}
                  </Header>
                  <Content
                    // className="site-layout-background"
                    style={{
                      // margin: "24px 16px",
                      // padding: 24,
                      // height: "100%",
                      overflow: "auto",
                    }}
                  >
                    <LoadingCompnent>{children}</LoadingCompnent>
                  </Content>
                </Layout>
              </Layout>
            )}

            {!loggedIn && <LoadingCompnent>{children}</LoadingCompnent>}
          </ErrorBoundary>
        </UserContext.Provider>
      </ConfigProvider>
    </UIContext.Provider>
  );
}
export default React.memo(AppFrame);

const LoadingCompnent = function ({ children }: { children: any }) {
  return (
    <Suspense
      fallback={
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Spin size="large" />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};
