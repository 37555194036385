import { UserProfile } from "config/jtTypes";
import React from "react";

export const NoticeAction = {
  showNotice: "showNotice",
  hideNotice: "hideNotice",
};
export const NoticeType = Object.freeze({
  error: "error",
  warning: "warning",
  info: "info",
  success: "success",
});
export const NoticeContext = React.createContext({
  message: "",
  type: NoticeType.info,
  showNotice: () => {}, // message, type
  hideNotice: () => {},
});

export const GraphQLContext = React.createContext({});

export const CartContext = React.createContext({});

export const UserAction = {
  setProfile: "setProfile",
  logout: "logout",
};

type UserContextType = {
  user?: UserProfile;
  setProfile?: (profile: any) => void;
  logout?: () => void;
};
const userDefault: UserContextType = {
  user: undefined,
  setProfile: undefined,
  logout: undefined,
};
export const UserContext = React.createContext(userDefault);

export const OrderContext = React.createContext({
  orders: [],
  setOrders: undefined,
});
export const UIContext = React.createContext({});
