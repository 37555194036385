import React from "react";
import {
  AccountManagerIcon,
  DashboardIcon,
  HeartIcon,
  ManagerIcon,
} from "components/general/icon";

import RouteAuth from "components/Route/RouteAuth";
import { Route } from "react-router-dom";
import {MailOutlined, UserSwitchOutlined} from "@ant-design/icons";
import {authService} from "../services/auth";

function asyncComponentCreator(url: string) {
  return React.lazy(() => import(`pages/${url}/index.tsx`));
}
export type RouterType = {
  path: string;
  redirect?: string; // path redirect when click into, use for breadcum
  breadcrumbPath?: string;
  name: string;
  exact: boolean;
  tag?: React.ReactNode;
  icon?: JSX.Element;
  component?: any;
  sidebarVisible?: Boolean;
  childrens?: RouterType[];
};

const limited = (visible: boolean) => {
  const user = authService.getUserInfoLocal();
  return user?.userName !== 'care' && visible
}

const routes: RouterType[] = [
  {
    path: "/login",
    name: "Đăng nhập",
    exact: true,
    tag: Route,
    component: asyncComponentCreator("auth/login"),
  },
  {
    path: "/",
    exact: true,
    icon: <DashboardIcon />,
    name: "Trang chủ",
    sidebarVisible: true,
    tag: RouteAuth,
    component: asyncComponentCreator("home"),
  },
  {
    path: "/statistical",
    exact: true,
    icon: <ManagerIcon />,
    name: "Thống kê",
    sidebarVisible: limited(true),
    tag: RouteAuth,
    component: asyncComponentCreator("statistical"),
  },
  {
    path: "/shops",
    exact: true,
    icon: <AccountManagerIcon />,
    name: "Quản lý khách hàng",
    sidebarVisible: limited(true),
    tag: RouteAuth,
    component: asyncComponentCreator("shop"),
  },
  {
    path: "/shops/:id/orders",
    exact: true,
    name: "Tra cứu đơn hàng",
    sidebarVisible: limited(false),
    tag: RouteAuth,
    component: asyncComponentCreator("order/query"),
  },
  {
    path: "/shops/:id/cod",
    exact: true,
    name: "Quản lý COD",
    sidebarVisible: limited(true),
    tag: RouteAuth,
    component: asyncComponentCreator("manage/cod"),
  },
  {
    path: "/shops/:id/tracking",
    exact: true,
    name: "Quản lý COD",
    sidebarVisible: limited(true),
    tag: RouteAuth,
    component: asyncComponentCreator("service"),
  },
  {
    path: "/orders",
    exact: true,
    icon: <AccountManagerIcon />,
    name: "Tra cứu đơn hàng",
    sidebarVisible: true,
    tag: RouteAuth,
    component: asyncComponentCreator("order/query"),
  },
  //  {
  //   path: "/ticket",
  //   // redirect: "/service/expressTrack",
  //   exact: true,
  //   icon: <ApartmentOutlined />,
  //   name: "Xử lý đơn hàng",
  //   sidebarVisible: limited(true),
  //   tag: RouteAuth,
  //   component: asyncComponentCreator("manage/ticket"),
  // },
  {
    path: "/orderWaybill",
    exact: true,
    icon: <AccountManagerIcon />,
    name: "Vận đơn của tôi",
    sidebarVisible: limited(true),
    tag: RouteAuth,
    component: asyncComponentCreator("manage/my_order"),
  },
  // {
  //   path: "/cods",
  //   exact: true,
  //   icon: <AccountManagerIcon />,
  //   name: "Quản lý tài chính",
  //   sidebarVisible: limited(true),
  //   tag: RouteAuth,
  //   component: asyncComponentCreator("manage/cod"),
  // },
  {
    path: "/newcods",
    exact: true,
    icon: <AccountManagerIcon />,
    name: "Quản lý dòng tiền",
    sidebarVisible: limited(true),
    tag: RouteAuth,
    component: asyncComponentCreator("manage/cod_new"),
  },
  {
    path: "/cod-return",
    exact: true,
    icon: <AccountManagerIcon />,
    name: "Quản lý tiền hoàn",
    sidebarVisible: limited(true),
    tag: RouteAuth,
    component: asyncComponentCreator("manage/cod_return"),
  },
  {
    path: "/emails",
    exact: true,
    icon: <MailOutlined />,
    name: "Gửi Email",
    sidebarVisible: limited(true),
    tag: RouteAuth,
    component: asyncComponentCreator("email"),
  },
  // {
  //   path: "/partial",
  //   exact: true,
  //   icon: <AccountManagerIcon />,
  //   name: "Giao 1 phần",
  //   sidebarVisible: limited(true),
  //   tag: RouteAuth,
  //   component: asyncComponentCreator("manage/partial"),
  // },
  {
    path: "/track",
    exact: true,
    icon: <HeartIcon />,
    name: "Tra hành trình",
    sidebarVisible: true,
    tag: RouteAuth,
    component: asyncComponentCreator("track"),
  },
  {
    path: "/service/:id",
    exact: true,
    icon: <HeartIcon />,
    name: "Tra hành trình",
    sidebarVisible: limited(false),
    tag: RouteAuth,
    component: asyncComponentCreator("service"),
  },
  {
    path: "/vietquick-shippers",
    exact: true,
    icon: <UserSwitchOutlined />,
    name: "Quản lý bưu tá",
    sidebarVisible: limited(true),
    tag: RouteAuth,
    component: asyncComponentCreator("vietquick/shipper"),
  },
  {
    path: "/vietquick-orders",
    exact: true,
    icon: <AccountManagerIcon />,
    name: "Nội thành",
    sidebarVisible: limited(true),
    tag: RouteAuth,
    component: asyncComponentCreator("vietquick/query"),
  },
  {
    path: "/account",
    exact: true,
    icon: <HeartIcon />,
    name: "Quản lý tài khoản",
    sidebarVisible: limited(true),
    tag: RouteAuth,
    component: asyncComponentCreator("account"),
  },
  {
    path: "/404",
    name: "404",
    exact: true,
    tag: Route,
    component: asyncComponentCreator("error"),
  },
];
let routing: RouterType[] = [];
let breadcrumbRouters: { [key: string]: RouterType } = {};
function routingFnCreator(useFor = "all") {
  const fns = {
    // Returns routing for sidebar menu
    sidebar: (x = routes): RouterType[] => {
      return x.filter(function (item) {
        return item.sidebarVisible === true;
      });
    },
    breadcrumb: (): { [key: string]: RouterType } => {
      if (Object.keys(breadcrumbRouters).length > 0) {
        return breadcrumbRouters;
      }
      routes.forEach(function (item: RouterType) {
        if (!item.childrens && item.tag) {
          if (item.breadcrumbPath) {
            breadcrumbRouters[item.breadcrumbPath] = item;
          } else {
            breadcrumbRouters[item.path] = item;
          }

          // breadcrumbRouters.push(_.pick(item, ['path', 'name', 'icon', 'external', 'strict', 'exact', 'children', 'startGroup', 'startTitle', 'endGroup']));
        } else if (Array.isArray(item.childrens) && item.childrens.length > 0) {
          item.childrens.forEach(function (child: RouterType) {
            if (child.tag) {
              if (child.breadcrumbPath) {
                breadcrumbRouters[child.breadcrumbPath] = child;
              } else {
                breadcrumbRouters[child.path] = child;
              }
              // breadcrumbRouters.push(_.pick(child, ['path', 'name', 'icon', 'external', 'strict', 'exact', 'children', 'startGroup', 'startTitle', 'endGroup']));
            }
          });
          breadcrumbRouters[item.path] = {
            ...item,
            childrens: undefined,
          };
        }
      });
      return breadcrumbRouters;
    },
    // Returns routing for React-Router
    routing: (): RouterType[] => {
      if (routing.length > 0) {
        return routing;
      }

      routing = routes.filter((a) => {
        if (!!a.tag) {
          return true;
        }
        if (a.childrens) {
          let results = a.childrens.filter((c) => !!c.tag);
          return results;
        }
      });
      return routing;
    },
    all: (): RouterType[] => {
      return routes;
    },
  };
  switch (useFor) {
    case "sidebar":
      return fns.sidebar;
    case "routing":
      return fns.routing;
    case "breadcrumb":
      return fns.breadcrumb;
    default:
      return fns.all;
  }
}

export const getRoutes = routingFnCreator();
export const getSidebarRoutes = routingFnCreator("sidebar");
export const getRouterRoutes = routingFnCreator("routing");
export const getBreadcrumbRoutes = (): { [key: string]: RouterType } => {
  if (Object.keys(breadcrumbRouters).length > 0) {
    return breadcrumbRouters;
  }
  routes.forEach(function (item: RouterType) {
    if (!item.childrens && item.tag) {
      if (item.breadcrumbPath) {
        breadcrumbRouters[item.breadcrumbPath] = item;
      } else {
        breadcrumbRouters[item.path] = item;
      }

      // breadcrumbRouters.push(_.pick(item, ['path', 'name', 'icon', 'external', 'strict', 'exact', 'children', 'startGroup', 'startTitle', 'endGroup']));
    } else if (Array.isArray(item.childrens) && item.childrens.length > 0) {
      item.childrens.forEach(function (child: RouterType) {
        if (child.tag) {
          if (child.breadcrumbPath) {
            breadcrumbRouters[child.breadcrumbPath] = child;
          } else {
            breadcrumbRouters[child.path] = child;
          }
          // breadcrumbRouters.push(_.pick(child, ['path', 'name', 'icon', 'external', 'strict', 'exact', 'children', 'startGroup', 'startTitle', 'endGroup']));
        }
      });
      breadcrumbRouters[item.path] = {
        ...item,
        childrens: undefined,
      };
    }
  });
  return breadcrumbRouters;
};
