import { CountryAddress, UserProfile } from "config/jtTypes";
import Cookies from "js-cookie";
// import jwtDecode from "jwt-decode";

export const JWT_TOKEN = "JWT_ADMIN_TOKEN";
export const JWT_JT_TOKEN = "JWT_JT_TOKEN";
export const USER_ID = "USER_ID";
export const USER_INFO = "USER_INFO_ADMIN";
export const GENERAL_ADDRESS = "GENERAL_ADDRESS";
const expires = 30;

export function getLocalToken() {
  return Cookies.get(JWT_TOKEN);
}

export function resetLocalToken() {
  Cookies.remove(JWT_TOKEN);
}
type JwtInfo = {
  userId: any;
};
export function setLocalToken(token: string) {
  Cookies.set(JWT_TOKEN, token, { expires: 365 });
  // if (token) {
  //   try {
  //     var decoded: JwtInfo = jwtDecode(token);
  //     console.log("decoded: ", decoded);
  //     if (decoded && decoded.userId) {
  //       setUserId(decoded.userId);
  //     }
  //   } catch (_) {}
  // }
}
// jtToken

export function getJtToken() {
  return Cookies.get(JWT_JT_TOKEN);
}

export function setJtToken(token: string) {
  Cookies.set(JWT_JT_TOKEN, token, { expires: 365 });
}

export const isLoggedIn = () => getLocalToken() != null;

export function setUserId(userId: any) {
  Cookies.set(USER_ID, userId);
}

export function getUserId() {
  return Cookies.get(USER_ID);
}

export function setUserInfo(info: string) {
  localStorage.setItem(USER_INFO, info);
}
export function getUserInfo(): UserProfile | undefined {
  let result = localStorage.getItem(USER_INFO);
  try {
    if (result) {
      let userInfo: UserProfile = JSON.parse(result);
      return userInfo;
    }
  } catch (_) {}
  return undefined;
}

export function saveCountryAddress(address: CountryAddress[]) {
  localStorage.setItem(GENERAL_ADDRESS, JSON.stringify(address));
}
export async function getCountryAddress() {
  const address = await localStorage.getItem(GENERAL_ADDRESS);
  try {
    let result: CountryAddress[] = JSON.parse(address ?? "");
    return result;
  } catch (_) {}
  return undefined;
}
