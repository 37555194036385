import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { message } from "antd";
import { getErrorMessage } from "./helper";
import { getLocalToken, resetLocalToken } from "./localStorage";
import { createUploadLink } from "apollo-upload-client";

const errLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const error: any = graphQLErrors[0];
    console.log(
      `[GraphQL error]: Message: ${error.message}, 
      Location: ${error.locations},
       Path: ${error.path}`
    );
    if (error.extensions?.code == 401 || error.name === "UNAUTHENTICATED") {
      if (typeof window !== "undefined") {
        resetLocalToken();
        if (window.location.pathname !== "/login") {
          window.location.href = "/login";
        }
      }
    }
    //  else {
    //   message.error(getErrorMessage(error.message));
    // }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // message.error(getErrorMessage(networkError));
  }
});
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext((props: any) => ({
    headers: {
      authorization: getLocalToken(), // however you get your token
      ...props.headers,
    },
  }));
  return forward(operation);
});
// const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });
const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  // credentials: "same-origin",
  // headers: {
  //   "Accept-Language": language,
  // },
});

export const apoloClient = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  link: from([errLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
