import React from "react";
import "./sidebar.css";
import { Drawer, Layout, Menu } from "antd";
import SideMenu from "./menu";
const { Sider } = Layout;

function SideBar({
  open,
  docked,
  collapsed,
  onClose,
}: {
  open: boolean;
  docked: boolean;
  collapsed: boolean;
  onClose: (e: any) => void;
}) {
  if (docked) {
    return (
      <Sider
        style={{ zIndex: 5 }}
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={250}
      >
        <SideMenu collapsed={collapsed} />
      </Sider>
    );
  }
  return (
    <Drawer
      style={{ zIndex: 5 }}
      placement="left"
      closable={docked}
      onClose={onClose}
      visible={open}
      bodyStyle={{
        padding: "0px",
      }}
    >
      <SideMenu onSelectMenu={onClose} />
    </Drawer>
  );
}
export default React.memo(SideBar);
