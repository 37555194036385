import React, { useMemo } from "react";

export default function Label({
  title,
  children,
  textAlign = "left",
  color,
  fontWeight,
  fontSize,
  padding,
  margin,
  style,
  className = "",
  type = "Main",
  breakLine = false,
}: {
  title: string;
  children?: any;
  textAlign?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  padding?: string;
  margin?: string;
  style?: any;
  className?: string;
  type?:
    | "Main"
    | "Title"
    | "SubTitle"
    | "Header"
    | "Menu"
    | "Caption"
    | "Placeholder";
  breakLine?: boolean;
}) {
  const styleValue: Object = useMemo(() => {
    let txtStyle: any = { fontSize: 14, color: "#253f60" };
    switch (type) {
      case "Header":
        txtStyle["fontSize"] = 18;
        txtStyle["fontWeight"] = "bold";
        break;
      case "Title":
        txtStyle["fontSize"] = 14;
        txtStyle["fontWeight"] = "600";
        break;
      case "SubTitle":
        txtStyle["fontSize"] = 14;
        break;
      case "Caption":
        txtStyle["fontSize"] = 12;
        break;
      case "Placeholder":
        txtStyle["fontSize"] = 13;
        txtStyle["color"] = "#ccc;";
        break;
    }
    if (fontSize) {
      txtStyle.fontSize = fontSize;
    }
    if (fontWeight) {
      txtStyle.fontWeight = fontWeight;
    }
    if (padding) {
      txtStyle.padding = padding;
    }
    if (margin) {
      txtStyle.margin = margin;
    }
    if (textAlign) {
      txtStyle.textAlign = textAlign;
    }
    if (color) {
      txtStyle.color = color;
    }
    return {
      ...txtStyle,
      ...style,
    };
    // eslint-disable-next-line
  }, [color, fontWeight, style]);

  if (breakLine) {
    return (
      <div color="inherit" style={styleValue} className={className}>
        {title || children}
      </div>
    );
  }
  return (
    <span color="inherit" style={styleValue} className={className}>
      {title || children}
    </span>
  );
}
