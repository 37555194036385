import React, { useContext } from "react";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { LangIcon, NotifyIcon } from "./narbar_icon";
import { Breadcrumb, Badge, Dropdown, Menu } from "antd";
import { Env } from "config/constants";
import { Link, useLocation } from "react-router-dom";
import { getBreadcrumbRoutes, RouterType } from "routing/routes";
import { UserContext } from "context";
import useWindowSize from "hooks/useWindowSize";

const breadcrumbRoutes: { [key: string]: RouterType } = getBreadcrumbRoutes();
function BreadcrumbHeader({ pathnames }: { pathnames: string[] }) {
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">Trang chủ</Link>
      </Breadcrumb.Item>
      {pathnames.map((value: string, index: number) => {
        // const last = index === pathnames.length - 1;
        const path = `/${pathnames.slice(0, index + 1).join("/")}`;
        const info = breadcrumbRoutes[path] ?? {
            redirect: "",
            path:"",
            name:""
        };
        return (
          <Breadcrumb.Item key={"breadcurm_" + index}>
            <Link to={info.redirect ?? info.path}>{info.name ?? value}</Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
}
export default function Narbar({
  drawerCollapsed,
  toggleDrawer,
}: {
  drawerCollapsed: boolean;
  toggleDrawer: (e: any) => void;
}) {
  let location = useLocation();
  const { user, logout } = useContext(UserContext);
  const pathnames = location.pathname.split("/").filter((x: string) => x);
  const size = useWindowSize();
  const menu = (
    <Menu>
      {/* <Menu.Item>
        <div>
          <SettingOutlined />
          <Link to="#" style={{ color: "#606266" }}>
            {" "}
            Cài đặt cá nhân
          </Link>
        </div>
      </Menu.Item> */}
      <Menu.Item
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <LogoutOutlined />
        <Link
          to="/login"
          style={{ color: "#606266", flex: 1 }}
          onClick={() => {
            logout && logout();
          }}
        >
          {" "}
          Đăng xuất
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="side_navbar">
      <div className="navbar_tools_left">
        {React.createElement(
          drawerCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            width: "20px",
            height: "20px",
            onClick: toggleDrawer,
            style: { fontSize: 20, margin: "0px 8px" },
          }
        )}
        <BreadcrumbHeader pathnames={pathnames} />
      </div>
      <div className="navbar_tools_right">
        {/* {size.width >= 500 && (
          <React.Fragment>
            <Badge count={0} showZero style={{ color: "white" }} size="small">
              <NotifyIcon />
            </Badge>
            <LangIcon />
          </React.Fragment>
        )} */}
        <Dropdown overlay={menu}>
          <a target="_blank" style={{ color: "#606266" }}>
            <img
              src="/img/user-img.4162ac19.png"
              alt=""
              className="narbar_avatar"
            />
            {!Env.hiddenMode && size.width >= 768 && (
              <React.Fragment>
                {user?.fullName ?? "User"} <DownOutlined />
              </React.Fragment>
            )}
          </a>
        </Dropdown>
      </div>
    </div>
  );
}
