import { COOKIES, LOCALSTORAGE } from "config/constants";
import { LoginResponse, UserProfile } from "config/jtTypes";
import { client } from "./client";
import Cookies from "js-cookie";

export const authService = {
  isLoggedIn() {
    return !!Cookies.get(COOKIES.ACCESS_TOKEN);
  },
  getAccessToken() {
    return Cookies.get(COOKIES.ACCESS_TOKEN);
  },
  setUserInfo(info: UserProfile) {
    localStorage.setItem(LOCALSTORAGE.USER_INFO, JSON.stringify(info));
  },
  getUserInfoLocal() {
    const result = localStorage.getItem(LOCALSTORAGE.USER_INFO);
    try {
      if (result) {
        const userInfo: UserProfile = JSON.parse(result);
        return userInfo;
      }
    } catch (_) {}
    return undefined;
  },
  getUserInfoServer() {
    return client.post("/auth/me").then((res) => {
      this.setUserInfo(res.data);
      return res.data;
    });
  },
  login(userName: string, password: string) {
    return client.post("/auth/login", { userName, password }).then((res) => {
      const data: LoginResponse = res.data;
      const expireDate = new Date(new Date().getTime() + data.expiresIn * 1000);
      Cookies.set(COOKIES.ACCESS_TOKEN, data.accessToken, {
        expires: expireDate,
      });
    });
  },
  logout() {
    localStorage.removeItem(LOCALSTORAGE.USER_INFO);
    Cookies.remove(COOKIES.ACCESS_TOKEN);
  },
  changePassword(data: any) {
    return client.post("/auth/change-password", data).then((res) => {
      const data: LoginResponse = res.data;
      const expireDate = new Date(new Date().getTime() + data.expiresIn * 1000);
      Cookies.set(COOKIES.ACCESS_TOKEN, data.accessToken, {
        expires: expireDate,
      });
    });
  },
};
