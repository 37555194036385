import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const RouteAuth = (props) => {
  let { canAccess, path } = props;
  let redirectPath = `/login?next=${path}`;
  if (canAccess(path)) {
    return <Route {...props} />;
  }
  return <Redirect to={redirectPath} />;
};
RouteAuth.propTypes = {
  canAccess: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};
export default RouteAuth;

export const RouteHasAuth = (props) => {
  let { canAccess, path } = props;
  return canAccess(path) ? <Route {...props} /> : <Redirect to="/" />;
};
RouteHasAuth.propTypes = {
  canAccess: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};
