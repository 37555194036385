import React from "react";

// import Label from 'components/views/Label/Label'
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    if (error) {
      console.log("getDerivedStateFromError: ", error);
      return { hasError: true };
    }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log("componentDidCatch: ", error);
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              margin: "auto",
              backgroundColor: "#131432",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ margin: "auto", color: "white" }}>
              Có lỗi xảy ra vui lòng làm mới trang web để tiếp tục sử dụng.
            </span>
          </div>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}
