import React from "react";
import PropTypes from "prop-types";
import {Switch} from "react-router-dom";
import {authService} from "services/auth";

var routesRendered = undefined;

function RoutingWrapper({routes}) {
  const canAccess = (path) => {
    const hasAuth = authService.isLoggedIn();
    const authPath = "/login";
    const allowedToVisitPath = [authPath];

    if (hasAuth && path === authPath) {
      // has authen and is login path redirect to home page
      return false;
    } else if (!hasAuth && !allowedToVisitPath.includes(path)) {
      return false;
    }

    const user = authService.getUserInfoLocal();
    return user?.userName !== 'ngoctramadmin' || ['/orders', '/track', '/'].includes(path);
  };

  if (!routesRendered) {
    // render components that are inside Switch (main view)
    routesRendered = routes.map((a, i) => {
      if (a.childrens) {
        let result = a.childrens.map((c, i) => {
          // Get tag for Route.
          // Is it "RouteAuth" (e.g. `protected route`) or "Route"?
          const Tag = c.tag;
          // Select only props that we need
          const p = {canAccess, ...c};
          //Tag can <RouteAuth key={}/>
          // @ts-ignore
          return <Tag key={"tag_route_" + i} {...p} />;
        });
        // console.log(result);
        return result;
      }

      // Get tag for Route.
      // Is it "RouteAuth" (e.g. `protected route`) or "Route"?
      const Tag = a.tag;
      // Select only props that we need
      const b = {canAccess, ...a};
      // console.log({...b})
      //Tag can <RouteAuth key={}/>
      return <Tag key={i} {...b} />;
    });
  }
  // console.log(routesRendered)
  return <Switch>{routesRendered}</Switch>;
  // return <Switch>
  //   <Route exact path='/login' component={Login} />
  // </Switch>
}

RoutingWrapper.propTypes = {
  routes: PropTypes.array,
};
export default RoutingWrapper;
